<template>
  <Popup ref="popupRef" width="380px" :show-cancel="false" :show-confirm="false">
    <div class="detail" v-if="detail">
      <div class="card base">
        <div class="flex between-center">
          <div class="flex-1">
            <div class="flex items-center mb4">
              <div class="ptitle">{{detail.title}}</div>
              <img class="img-tag" src="@/assets/images/tui.png" v-if="(detail.taskTag&1)==1">
              <img class="img-tag" src="@/assets/images/ding.png" v-if="(detail.taskTag&2)==2">
              <img class="img-tag" src="@/assets/images/bao.png" v-if="(detail.taskTag&4)==4">
            </div>
            <div class="flex items-center">
              <div class="pname">{{detail.projectName}}</div>
              <div class="ptag" >{{detail.taskTypeName}}</div>
            </div>
          </div>
          <img class="avatar" src="@/assets/images/avatar-default.png">
        </div>
        <div class="flex between-center">
          <div class="price">+{{detail.price}} <span class="unit">{{detail.priceUnit}}</span></div>
          <span class="task-id">ID:{{detail.taskId}}</span>
        </div>
        <div class="count">
          <div>
            <div class="num">{{getDurationTime(detail.duration)}}</div>
            <div>做单时间</div>
          </div>
          <div>
            <div class="num">{{getDurationTime(detail.auditDuration)}}</div>
            <div>审核时间</div>
          </div>
          <div>
            <div class="num">{{detail.completeNum}}</div>
            <div>完成数量</div>
          </div>
          <div>
            <div class="num">{{detail.inventory}}</div>
            <div>剩余数量</div>
          </div>
        </div>
        <div class="device-row">
          <div class="flex items-center">
            <img src="@/assets/images/phone.png">
            <div>{{platformName}}</div>
          </div>
          <div class="flex items-center">
            <img src="@/assets/images/repeat.png">
            <div>{{repeatDesc}}</div>
          </div>
        </div>
      </div>
      <!-- 做单说明 -->
      <div class="card description" v-if="detail.description">
        <div class="title-row">
          <img src="@/assets/images/icon-warn-fill.png">
          <span>做单说明</span>
        </div>
        <div class="content">{{detail.description}}</div>
      </div>
      <!-- 多套步骤 -->
      <div class="card flex justify-end">
        <!-- <span class="bold">多套步骤</span> -->
        <el-select :value="stepUnitIndex" placeholder="请选择" v-if="detail.stepUnits && detail.stepUnits.length > 0" size="mini" @change="selectChange">
          <el-option
            v-for="(item, index) in detail.stepUnits"
            :key="index"
            :label="`第${index+1}套(剩余库存:${item.inventory})`"
            :value="index">
          </el-option>
        </el-select>
      </div>
      <!-- 任务步骤 -->
      <div class="card step">
        <div class="title-row">
          <img src="@/assets/images/icon-step.png">
          <span>任务步骤</span>
        </div>
        <div class="mt8" v-for="(item, index) in steps" :key="index">
          <div class="step-title">
            <div class="sort">{{index + 1}}</div>
            <div>{{item.stepTitle}}</div>
          </div>
          <LoadImage v-if="item.stepType == 0" class="step-img" :src="item.stepImg || item.stepContent"/>
          <el-input v-else size="mini" disabled :value="item.stepContent"></el-input>
        </div>
      </div>
      <!-- 上传验证 -->
      <div class="card step mt12">
        <div class="title-row">
          <img src="@/assets/images/icon-step.png">
          <span>上传验证</span>
        </div>
        <div class="mt8" v-for="(item, index) in verifySteps" :key="index">
          <div class="step-title">
            <div class="sort">{{index + 1}}</div>
            <div>{{item.stepTitle}}</div>
          </div>
          <LoadImage v-if="item.stepType == 1" class="step-img" :src="item.stepImg || item.stepContent"/>
          <el-input v-else size="mini" disabled :value="item.stepContent"></el-input>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import LoadImage from '@/components/LoadImage'
import { taskDetailApi } from '@/api/task.js'
export default {
  components: {
    Popup,
    LoadImage
  },
  data() {
    return {
      detail: null,
      stepUnitIndex: 0,
      verifySteps: [],
      steps: []
    }
  },
  computed: {
    platformName() {
      if(!this.detail) return ''
      const map = {
        'android': '安卓',
        'ios': '苹果'
      }
      return map[this.detail.devicePlatform] || '不限接单设备'
    },
    repeatDesc() {
      if(!this.detail) return ''
      if(this.detail.repeat) return '不可重复接单'
      const repeatTimeDesc = this.detail.repeatTimes == 0 ? '不限次' : `每天${this.detail.repeatTimes}次`
      const repeatDayDesc = this.detail.repeatDays == 0 ? '不限天' : `每天${this.detail.repeatDays}次`
      return repeatTimeDesc + ' ' + repeatDayDesc
    }
  },
  methods: {
    open(taskId) {
      taskDetailApi({taskId}).then(res => {
        console.log('任务详情', res)
        this.detail = res.data
        if(res.data.stepUnits?.length > 0) {
          this.steps = res.data.stepUnits[0].steps || []
          this.verifySteps =res.data.stepUnits[0].verifySteps || []
        } else {
          this.steps = res.data.steps || []
          this.verifySteps = res.data.verifySteps || []
        }
        this.$refs.popupRef.open()
      }).catch(err => {
        this.$tips({ type: 'error', message: err })
      })
    },
    close() {
      this.$refs.popupRef.close()
    },
    getDurationTime(second) {
      let minute = parseInt(second / 60);
      if (minute < 60) {
        return minute + '分钟'
      } else if (60 <= minute && minute <= 1440) {
        let result = parseInt(minute / 60);
        return result + '小时'
      } else if (minute >= 1440) {
        let result = parseInt(minute / 1440);
        return result + '天'
      }
    },
    selectChange(e) {
      console.log(e)
      this.stepUnitIndex = e
      const target = this.detail.stepUnits[this.stepUnitIndex]
      this.steps = target.steps || []
      this.verifySteps = target.verifySteps || []
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 12px;
}

::-webkit-scrollbar {
	width: 3px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.detail {
  height: 70vh;
  overflow-y: auto;
  border-radius: 12px;
}
.card {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
}
.base {
  background: linear-gradient(to bottom, rgba(255,255,255,.8), #fff);
  .avatar {
    width: 44px;
    height: 44px;
    border-radius: 22px;
  }
  .ptitle {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: bold;
  }
  .img-tag {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
  .pname {
    font-size: 12px;
    color: #65666F;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ptag {
    font-size: 11px;
    color: #B0B3BF;
    padding: 0 4px;
    height: 16px;
    line-height: 16px;
    border-radius: 8px;
    border: 1px solid #B0B3BF;
    margin-left: 4px;
  }
  .price {
    color: var(--theme-color-red);
    font-size: 20px;
    font-weight: bold;
    margin-top: 4px;
    .unit {
      font-size: 13px;
    }
  }
  .task-id {
    color: #B0B3BF;
    font-size: 12px;
  }
  .count {
    display: flex;
    margin-top: 16px;
    &>div {
      flex: 1;
      font-size: 11px;
      color: #B0B3BF;
      text-align: center;
      .num {
        font-size: 14px;
        font-weight: bold;
        color: #2A2A2A;
        margin-bottom: 2px;
      }
    }
  }
  .device-row {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    margin-top: 16px;
    color: #B0B3BF;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
.tips {
  white-space: pre-line;
  font-size: 11px;
  color: #B0B3BF;
  line-height: 16px;
}
.title-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 12px;
  border-bottom: 1px solid #f5f5f5;
  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}
.description {
  padding: 12px 12px 0;
  .content {
    font-size: 12px;
    color: #65666F; 
    padding: 12px 0;
    line-height: 16px;
    white-space: pre-line;
  }
}
.step {
  .step-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 8px;
    .sort {
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-size: 10px;
      color: var(--theme-color);
      border: 1px solid var(--theme-color);
      border-radius: 8px;
      text-align: center;
      margin-right: 8px;
    }
  }
  .step-img {
    width: 150px;
    height: 150px;
  }
}
</style>