import request from '@/utils/request'
/**
 * 任务管理
 */
export function taskManageApi(data = {}) {
	return request({
    url: 'task/manage',
    method: 'POST',
    data
  })
}

/**
 * 任务配置项（自动刷新/置顶等）
 */
export function taskJobsApi(data = {}) {
	return request({
    url: 'task/queryTaskJobs',
    method: 'POST',
    data
  })
}

/**
 * 个人任务列表
 */
export function pubTasksApi(data = {}) {
	return request({
    url: 'task/pubTasks',
    method: 'POST',
    data
  })
}

/**
 * 执行定时任务操作
 */
export function operateTaskJobApi(data = {}) {
	return request({
    url: 'task/operateTaskJob',
    method: 'POST',
    data
  })
}

/**
 * 添加定时任务
 */
export function addTaskJobApi(data = {}) {
	return request({
    url: 'task/addTaskJob',
    method: 'POST',
    data
  })
}

/**
 * 修改定时任务
 */
export function modifyTaskJobApi(data = {}) {
	return request({
    url: 'task/modifyTaskJob',
    method: 'POST',
    data
  })
}

/**
 * 查询定时任务配置
 */
export function queryTaskJobApi(data = {}) {
	return request({
    url: 'task/queryTaskJob',
    method: 'POST',
    data
  })
}

/**
 * 获取头条预约列表
 */
export function headlineAppointmentsApi(data = {}) {
	return request({
    url: 'task/headlineAppointments',
    method: 'POST',
    data
  })
}


/**
 * 获取预约头条任务可选列表
 */
export function headlineTaskChooseListApi(data = {}) {
	return request({
    url: 'task/headlineTaskChooseList',
    method: 'POST',
    data
  })
}

/**
 * 预约头条支付
 */
export function headlineAppointApi(data = {}) {
	return request({
    url: 'task/headlineAppoint',
    method: 'POST',
    data
  })
}

/**
 * 位置秒杀状态查询
 */
export function seckillSeatInfoApi(data = {}) {
	return request({
    url: 'task/seckillSeatInfo',
    method: 'POST',
    data
  })
}

/**
 * 位置秒杀
 */
export function seatSecKillApi(data = {}) {
	return request({
    url: 'task/seatSecKill',
    method: 'POST',
    data
  })
}

/**
 * 获取竞拍任务列表
 */
export function auctionTasksApi(data = {}) {
	return request({
    url: 'task/auctionTasks',
    method: 'POST',
    data
  })
}

/**
 * 极速审核任务管理
 */
export function quickAuditManageApi(data = {}) {
	return request({
    url: 'task/quickAuditManage',
    method: 'POST',
    data
  })
}

/**
 * 关键词搜索任务
 */
export function searchApi(data = {}) {
	return request({
    url: 'task/search',
    method: 'POST',
    data
  })
}

/**
 * 申请添加项目名
 */
export function addProjectApi(data = {}) {
	return request({
    url: 'task/addProject',
    method: 'POST',
    data
  })
}

/**
 * 搜索项目名称
 */
export function searchProjectsApi(data = {}) {
	return request({
    url: 'task/searchProjects',
    method: 'POST',
    data
  })
}

/**
 * 发布任务
 */
export function publishApi(data = {}) {
	return request({
    url: 'task/publish',
    method: 'POST',
    data
  })
}

/**
 * 任务详情
 */
export function taskDetailApi(data = {}) {
	return request({
    url: 'task/detail',
    method: 'POST',
    data
  })
}

/**
 * 修改任务图标
 */
export function changeTaskLogoApi(data = {}) {
	return request({
    url: 'task/changeTaskLogo',
    method: 'POST',
    data
  })
}

/**
 * 导出数据
 */
export function exportV2Api(data = {}) {
	return request({
    url: 'task/exportV2',
    method: 'POST',
    data
  })
}

/**
 * 竞拍任务修改
 */
export function modifyAuctionApi(data = {}) {
	return request({
    url: 'task/modifyAuction',
    method: 'POST',
    data
  })
}

/**
 * 查询发布管理页面的进行中与已完成任务列表
 */
export function userTaskPanelsApi(data = {}) {
	return request({
    url: 'task/userTaskPanels',
    method: 'POST',
    data
  })
}
