import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { userInfoApi } from '@/api/user.js'
import im from './modules/im.js'
import { $tips } from '@/plugins/element/element.js'
import JimClient from 'jim-client'

Vue.use(Vuex)

const userId = localStorage.getItem('USER_ID') || ''
export default new Vuex.Store({
  modules: {
    im
  },
  state: {
    dataKey: '',
		signKey: '',
    token: localStorage.getItem('TOKEN') || '',
    userInfo: JSON.parse(localStorage.getItem('USERINFO')) || {},
    memberBenefit: null, // 用户当前会员权益信息
    uploadConfig: null, // oss配置
    easysay: JSON.parse(localStorage.getItem(`EASYSAY_${userId}`)) || [], // 常用语
    spreadExcludeId: localStorage.getItem('SPREAD_EXCLUDE_ID') || '', // 其它任务推广功能排除的项（对应的index）
    isRealName: true, // 是否实名
  },
  getters: {
    isLogin: state => !!state.token
  },
  mutations: {
    SET_KEY(state, payload) {
			state.signKey = payload.signKey
			state.dataKey = payload.dataKey
		},
    LOGIN(state, data){
			state.token = data.loginToken
			localStorage.setItem('TOKEN', data.loginToken)
			localStorage.setItem('USER_ID', data.userId)
		},
    LOGOUT(state) {
      state.token = ''
      const userId = localStorage.getItem('USER_ID')
      localStorage.removeItem('TOKEN')
      localStorage.removeItem('USER_ID')
      localStorage.removeItem('USERINFO')
      localStorage.removeItem(`EASYSAY_${userId}`)
      JimClient.disconnect(true)
      router.push('/login')
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data
      localStorage.setItem('USERINFO', JSON.stringify(data))
    },
    SET_MEMBER_BENEFIT(state, data) {
      state.memberBenefit = data
    },
    SET_UPLOAD_CONFIG(state, data) {
      state.uploadConfig = data
    },
    SET_EASYSAY(state, data) {
      state.easysay = data
      const userId = localStorage.getItem('USER_ID')
      localStorage.setItem(`EASYSAY_${userId}`, JSON.stringify(data))
    },
    SET_SPREAD_EXCLUDE_ID(state, index) {
      state.spreadExcludeId = index
      localStorage.setItem('SPREAD_EXCLUDE_ID', index)
    },
    SET_REALNAME(state, val) {
      state.isRealName = val
    }
  },
  actions: {
    GET_USER_INFO(context) {
      return new Promise((resolve, reject) => {
        userInfoApi().then(res => {
          console.log('用户信息', res)
          context.commit('SET_USER_INFO', res.data)
          resolve(res)
        }).catch(err => {
          $tips({ type: 'error', message: err })
        })
      })
    }
  }
})
