<template>
  <div class="container">
    <div>
      <img class="img-name" src="@/assets/images/pc-name.png">
      <h5>让你发布任务更加高效</h5>
      <div class="tags">
        <div class="tag">
          <img src="@/assets/images/icon-gouxuan.png">
          <span>快捷发布</span>
        </div>
        <div class="tag">
          <img src="@/assets/images/icon-gouxuan.png">
          <span>安全可靠</span>
        </div>
        <div class="tag">
          <img src="@/assets/images/icon-gouxuan.png">
          <span>海量任务</span>
        </div>
      </div>
      <div class="download">
        <!-- <div class="btn-download">
          <img src="@/assets/images/apple.png">
          <span>ios下载</span>
        </div> -->
        <el-popover
          placement="bottom"
          width="270"
          trigger="click">
          <div class="btn-download" slot="reference">
            <img src="@/assets/images/android.png">
            <span>安卓下载</span>
          </div>
          <img style="width: 240px; height: 240px;" src="@/assets/images/qrcode-android.png">
        </el-popover>
      </div>
    </div>

    <!-- 登陆 -->
    <div class="login">
      <div class="title">登录/注册你的账户</div>
      <div class="tips">首次登录自动注册</div>
      <div class="tab">
        <span :class="isPwdLogin ? 'active': ''" @click="isPwdLogin = true">账号密码登录</span>
        <span :class="isPwdLogin ? '': 'active'" @click="isPwdLogin = false">手机验证码登录</span>
      </div>
      <el-input placeholder="输入手机号" maxlength="11" type="number" v-model="form.phone">
        <img slot="prefix" src="@/assets/images/icon-phone.png">
      </el-input>
      <el-input v-if="isPwdLogin" placeholder="输入密码" maxlength="20" :type="isPwdShow ? 'text' : 'password'" v-model="form.pwdOrSms">
        <img slot="prefix" src="@/assets/images/icon-pwd.png">
        <div slot="suffix" class="btn-pwd">
          <img v-if="isPwdShow" class="icon-eyes" src="@/assets/images/pwd-show.png" @click="isPwdShow = false">
          <img v-else class="icon-eyes" src="@/assets/images/pwd-hide.png" @click="isPwdShow = true">
        </div>
      </el-input>
      <el-input v-else placeholder="输入验证码" maxlength="6" v-model="form.pwdOrSms">
        <img slot="prefix" src="@/assets/images/icon-sms.png">
        <div slot="suffix" class="btn-sms">
          <span :class="[isSendSms ? 'sms-send': '']" @click="sendSms">{{isSendSms ? `剩余${countdown}s` : '发送验证码'}}</span>
        </div>
      </el-input>
      <div class="err-tips" v-if="errTips.length > 0">{{errTips}}</div>
      
      <div class="btn-login" @click="handleLogin">登录</div>
      <div class="agree">
        登录即同意
        <a class="policy" target="_blank" href="http://global.xuniuwang.com/zqb/policy/user_policy.html">《用户协议》</a>及
        <a class="policy" target="_blank" href="http://global.xuniuwang.com/zqb/policy/private.html">《隐私协议》</a>
      </div>
    </div>
  </div>
</template>

<script>
import { loginApi } from '@/api/user.js'
import { getDeviceId, pwdEncrypt } from '@/utils/util.js'
export default {
  name: 'Login',
  data() {
    return {
      isPwdLogin: false,
      isPwdShow: false,
      form: {
        phone: '',
        pwdOrSms: ''
      },
      errTips: '',
      countdown: 60,
      isSendSms: false
    }
  },
  created() {
    document.addEventListener('keyup', this.handleEnterKeyup)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEnterKeyup)
  },
  methods: {
    sendSms() {
      console.log(this.isSendSms)
      if(this.isSendSms) return
      if(!this.form.phone.trim()) return this.$tips({message:'请输入手机号', type: 'warning'})
      const formData = {
        did: getDeviceId(),
        loginType: 'initSms',
        loginId: this.form.phone,
        productType: 0
      }
      loginApi(formData).then(res => {
        this.isSendSms = true
        const timer = setInterval(() => {
          this.countdown--
          if(this.countdown == 0) {
            this.isSendSms = false
            this.countdown = 60
            clearInterval(timer)
          }
        }, 1000)
      }).catch(err => {
        this.$tips({  message: err, type: 'error' })
      })
    },
    handleLogin() {
      if(!this.form.phone.trim()) return this.$tips({message:'请输入手机号', type: 'warning'})
      if(!this.form.pwdOrSms.trim()) return this.$tips({message: this.isPwdLogin ? '请输入密码' : '请输入验证码', type: 'warning'})
      const formData = {
        did: getDeviceId(),
        loginId: this.form.phone,
        productType: 0,
      }
      if(this.isPwdLogin) {
        Object.assign(formData, {
          loginType: 'pwdLoginSnd',
          pwd: pwdEncrypt(this.form.pwdOrSms)
        })
      } else {
        Object.assign(formData, {
          loginType: 'smsLoginSnd',
          authCode: this.form.pwdOrSms
        })
      }
      loginApi(formData).then(res => {
        console.log('store', this.$store)
        this.$store.commit('LOGIN', res.data)
        this.$store.commit('SET_USER_INFO', {headImgUrl: res.data.headImgUrl})
        this.$router.push('/home')
      }).catch(err => {
        this.$tips({  message: err, type: 'error' })
      })
    },
    handleEnterKeyup(e) {
      if(e.keyCode == 13) {
        this.handleLogin()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 1200px;
  height: 100vh;
  background: url('~@/assets/images/login-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  
  .img-name {
    width: 468px;
    height: 88px;
  }
  h5 {
    font-size: 50px;
    color: #02081A;
    margin-top: 20px;
  }
  .tags {
    display: flex;
    align-content: center;
    font-size: 24px;
    margin-top: 40px;
    .tag {
      display: flex;
      align-items: center;
      margin-right: 42px;
      img {
        width: 40px;
        height: 40px;
        margin-left: 10px;
      }
    }
    
  }
  .download {
    display: flex;
    align-items: center;
    margin-top: 80px;
    .btn-download {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 244px;
      height: 88px;
      font-size: 24px;
      border-radius: 24px;
      background: #fff;
      margin-right: 40px;
      cursor: pointer;
      img {
        width: 32px;
        height: 32px;
        margin-right: 20px;
      }
    }
  }
  .login {
    width: 480px;
    position: relative;
    padding: 64px 56px;
    border-radius: 24px;
    overflow: hidden;
    border: 4px solid #fff;
    background: rgba(255,255,255,0.6);
    .title {
      font-size: 32px;
      font-weight: bold;
    }
    .tips {
      font-size: 24px;
      color: #65666F;
      margin-top: 12px;
    }
    .tab {
      display: flex;
      align-items: center;
      background: #F3F7FB;
      padding: 4px;
      margin-top: 48px;
      border-radius: 16px;
      font-size: 16px;
      span {
        display: inline-block;
        width: 180px;
        height: 48px;
        line-height: 48px;
        border-radius: 16px;
        text-align: center;
        cursor: pointer;
      }
      .active {
        background: #fff;
        color: var(--theme-color);
      }
    }
    .btn-login {
      height: 60px;
      line-height: 60px;
      color: #fff;
      border-radius: 16px;
      background: #fff;
      background: var(--theme-color);
      font-size: 20px;
      margin-top: 36px;
      text-align: center;
      cursor: pointer;
    }
    .agree {
      font-size: 16px;
      color: #B0B3BF;
      margin-top: 12px;
      span {
        color: var(--theme-color);
      }
    }
    .err-tips {
      font-size: 16px;
      color: #f35533;
      margin-top: 10px;
    }
  }
}
::v-deep .el-input {
  margin-top: 16px;
}
::v-deep .el-input .el-input__inner{
  height: 58px !important;
  border-radius: 16px;
  padding-left: 60px;
  padding-right: 90px;
  font-size: 16px;
}
.btn-pwd {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  img {
    width: 18px;
    height: 18px;
  }
}
.btn-sms {
  height: 58px;
  line-height: 58px;
  padding-right: 16px;
  span {
    cursor: pointer;
    color: var(--theme-color);
  }
  .sms-send {
    color: #999999;
  }
}

::v-deep .el-input__prefix {
  width: 60px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 26px;
    height: 26px;
  }
}
</style>