<template>
  <Popup title="调整任务" ref="popupRef" width="434px" confirm-text="立即调整" @confirm="confirm" @close="resetData">
    <div class="label">
      <span>调整价格</span>
      <el-switch v-model="isPrice" active-color="#FF7519" inactive-color="#B0B3BF" @change="handlePriceSwitch"></el-switch>
    </div>
    <div class="flex items-center" v-if="isPrice">
      <el-input :value="oldPrice" type="number" disabled>
        <template #suffix>
          <span style="line-height: 40px;">元</span>
        </template>
      </el-input>
      <img class="img-arrow" src="@/assets/images/icon-arrow.png">
      <el-input v-model="newPrice" type="number" placeholder="调整后的价格">
        <template #suffix>
          <span style="line-height: 40px;">元</span>
        </template>
      </el-input>
    </div>
    <div class="label">
      <span>调整数量</span>
      <el-switch v-model="isNum" active-color="#FF7519" inactive-color="#B0B3BF"  @change="handleInventorySwitch"></el-switch>
    </div>
    <template v-if="isNum">
      <div class="flex items-center" v-if="taskType != 24">
        <el-input :value="oldInventory" type="number" disabled>
          <template #suffix>
            <span style="line-height: 40px;">单</span>
          </template>
        </el-input>
        <img class="img-arrow" src="@/assets/images/icon-arrow.png">
        <el-input v-model="inventoryValue" type="number" placeholder="调整后的数量" @change="inventoryValueChnage">
          <template #suffix>
            <span style="line-height: 40px;">单</span>
          </template>
        </el-input>
      </div>
      <div v-else>
        <!-- 多套库存 -->
        <div class="flex items-center">
          <el-input :value="oldInventory" type="number" disabled>
            <template #suffix>
              <span style="line-height: 40px;">单</span>
            </template>
          </el-input>
          <img class="img-arrow" src="@/assets/images/icon-arrow.png">
          <el-input :value="newInventory" disabled type="number" placeholder="调整后的库存">
            <template #suffix>
              <span style="line-height: 40px;">单</span>
            </template>
          </el-input>
        </div>
        <div class="flex between-center mt16">
          <el-select class="flex-1" v-model="curStepUnitIndex" placeholder="请选择">
            <el-option
              v-for="(item, index) in stepUnits"
              :key="item.mid"
              :label="`第${index+1}套(剩余:${item.inventory}) ${item.inputValue ? '+' + item.inputValue : ''}`"
              :value="index">
            </el-option>
          </el-select>
          <el-input class="flex-1 ml16" placeholder="请输入要增加的库存" v-model="stepUnits[curStepUnitIndex].inputValue" @change="handleInputChange"></el-input>
        </div>
      </div>
    </template>
    <div class="label">调整任务费用：</div>
    <div class="fee">
      <div class="row">
        <span>任务费</span>
        <span>{{taskCount}}元</span>
      </div>
      <div class="row">
        <span>服务费</span>
        <span>{{serviceCount}}元</span>
      </div>
      <div class="row">
        <span>共需支付</span>
        <span class="red-text">{{totalPrice}}元</span>
      </div>
    </div>
    <div class="label">支付方式：</div>
    <TaskCoinPay></TaskCoinPay>
    <div class="rule">
      <div>1.价格与数量不强制同时调整，不需要调整的项需关闭开关</div>
      <div>2.点击可直接输入要调整的价格或数量</div>
      <div>3.要调整的价格/数量不能低于任务当前的价格/数量</div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import TaskCoinPay from '@/components/TaskCoinPay'
import { queryAdjustApi } from '@/api/merchant.js'
import { taskDetailApi } from '@/api/task.js'
export default {
  components: {
    Popup,
    TaskCoinPay
  },
  data() {
    return {
      act: 7,
      taskId: '',
      isPrice: false,
      isNum: false,
      newPrice: '',
      inventoryValue: '',
      oldPrice: 0, // 旧价格
      completeNum: 0, // 已完成任务数量
      oldInventory: 0, // 旧数量
      serveRate: 0, // 服务费率
      minServe: 0, // 最低服务费,
      taskCount: 0.00, //任务费
			serviceCount: 0.00, //服务费
      totalPrice: 0.00, // 总价
      taskType: '',
      stepUnits: [],
      curStepUnitIndex: 0, //当前选择库存下标
    }
  },
  computed: {
    newInventory() {
      if(this.taskType == 24) {
        if(this.stepUnits?.length == 0) return 0
        return this.stepUnits.reduce((total, item) => total + (isNaN(parseInt(item.inputValue)) ? 0 : parseInt(item.inputValue)), this.oldInventory)
      } else {
        return this.inventoryValue
      }
    }
  },
  watch: {
    newPrice() {
      this.handleChange()
    },
    newInventory() {
      this.handleChange()
    }
  },
  methods: {
    open(info) {
      console.log(info)
      if(info && info.detail) {
        this.act = info.act
        this.taskId = info.detail.taskId
        this.taskType = info.detail.taskType
        if(this.taskType == 24) {
          taskDetailApi({taskId: this.taskId}).then(res => {
            console.log('任务详情',res)
            if(res.data?.stepUnits?.length > 0) {
              this.stepUnits = res.data.stepUnits.map(item => {
                item.inputValue = ''
                return item
              })
            }
          })
        }
        queryAdjustApi({taskId: this.taskId}).then(res => {
          console.log('加价/加量',res)
          this.oldPrice = res.data.taskPrice
          this.completeNum = res.data.completeNum
          this.oldInventory = res.data.totalInventory
          this.serveRate = res.data.serveRate
          this.minServe = res.data.minServe
          this.$refs.popupRef.open()
        }).catch(err => {
          this.$tips({ type: 'error', message: err })
        })
      }
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.isPrice = false
      this.isNum = false
      this.newPrice = ''
      this.inventoryValue = ''
      
      this.taskCount = 0.00
      this.serviceCount = 0.00
      this.totalPrice = 0.00

      this.stepUnits = []
      this.curStepUnitIndex = 0
    },
    handleChange() {
      if(this.newPrice > this.oldPrice || this.newInventory > this.oldInventory) {
        const price = (this.isPrice && this.newPrice > this.oldPrice) ? this.newPrice : this.oldPrice
        const newInventory = (this.isNum && this.newInventory > this.oldInventory) ? this.newInventory : this.oldInventory
        let taskp = (price * newInventory) - (this.oldPrice * this.oldInventory) - this.completeNum * Math.abs(price - this.oldPrice)
        let servicep = taskp * this.serveRate
        this.taskCount = Number(taskp).toFixed(2)
        if (servicep > this.minServe) {
          this.serviceCount = servicep.toFixed(2)
        } else {
          this.serviceCount = this.minServe.toFixed(2)
        }
        let total = Number(taskp) + Number(this.serviceCount)
        this.totalPrice = parseFloat(total).toFixed(2)
      } else {
        this.taskCount = 0.00
        this.serviceCount = 0.00
        this.totalPrice = 0.00
      }
      
    },
    handlePriceSwitch(val) {
      // if(!val) this.newPrice = ''
    },
    handleInventorySwitch(val) {
      // if(!val) this.inventoryValue = ''
    },
    confirm() {
      if(!this.taskId) return
      // if(this.newInventory < this.oldInventory && this.newPrice < this.oldPrice) return this.$tips({message: '要调整的价格/数量不能低于任务当前的价格/数量', type: 'warning'})
      if(this.isNum && this.newInventory < this.oldInventory) return this.$tips({message: '要调整的数量不能低于任务当前的数量', type: 'warning'})
      if(this.isPrice && this.newPrice < this.oldPrice) return this.$tips({message: '要调整的价格不能低于任务当前的价格', type: 'warning'})
      if(!this.totalPrice || this.totalPrice == 0) return this.$tips({message: '至少需调整一项数据', type: 'warning'})
      const formData = {
        taskId: this.taskId,
        type: this.act,
        extParam: {
          totalPrice: String(this.totalPrice), //总价
        }
      }
      //增加价格
      if(this.isPrice) {
        formData.extParam.price = String(this.newPrice)
      }
      //增加库存
      if(this.isNum) {
        if(this.taskType == 24) {
          const obj = {}
          this.stepUnits.forEach(item => {
            // obj[item.mid] = item.inventory + Number(item.inputValue)
            obj[item.mid] = Number(item.inputValue)
          })
          Object.assign(formData.extParam, {
            stepMultiInventory: obj,
            inventory: Number(this.newInventory)
          })
        } else {
          Object.assign(formData.extParam, {
            inventory: Number(this.newInventory)
          })
        }
      }
      
      console.log('formData', formData)
      this.$emit('confirm', formData)
      this.close()
    },
    handleInputChange(e) {
      console.log(e)
      const target = this.stepUnits[this.curStepUnitIndex]
      target.inputValue = isNaN(parseInt(target.inputValue)) ? '' : parseInt(target.inputValue)
    },
    inventoryValueChnage() {
      this.inventoryValue = isNaN(parseInt(this.inventoryValue)) ? '' : parseInt(this.inventoryValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
  margin-top: 24px;
  &:nth-child(1) {
    margin-top: 0;
  }
}
.img-arrow {
  width: 14px;
  height: 10px;
  margin: 0 10px;
}
.fee {
  padding: 16px;
  background-color: #F6F6F6;
  border-radius: 10px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}
.rule {
  color: #B0B3BF;
  font-size: 12px;
  line-height: 20px;
  margin-top: 24px;
}
</style>