<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
  }
}
</script>

<style lang="scss">
  @import './styles/normalize.css';
  @import './styles/base.scss';
</style>
