import Vue from 'vue'
//  导入elementui样式
// import 'element-ui/lib/theme-chalk/index.css'
// 自定义
import './element-variables.scss'
import { Input, Message, Image, Popover, Dialog, Form, FormItem, Switch, Select, Option, TimePicker,
  MessageBox, Button, RadioGroup, Radio, Autocomplete, Upload, Checkbox, CheckboxGroup, Pagination, DatePicker,
  Dropdown, DropdownMenu, DropdownItem, Table, TableColumn, Alert, Loading, Menu, MenuItem } from 'element-ui'

Vue.use(Input)
Vue.use(Image)
Vue.use(Popover)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(TimePicker)
Vue.use(Button)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Autocomplete)
Vue.use(Upload)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Pagination)
Vue.use(DatePicker)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Alert)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Loading.directive)
Vue.prototype.$tips = Message

Vue.prototype.$confirm = confirm
function confirm(content, title = '提示', option) {
  
  return new Promise((resolve, reject) => {
    MessageBox.confirm(content, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showClose: false,
      type: 'warning',
      ...option
    }).then(action  => {
      resolve(action)
    }).catch(err => {
      reject(err) 
    });
  })
}

export const $tips =  Message
export const $confirm =  confirm
