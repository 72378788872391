<template>
  <div class="task-list" v-if="taskList.length > 0">
    <div class="task" v-for="(item) in taskList" :key="item.task.taskId">
      <div class="row pointer" @click="openDetail(item.task.taskId)">
        <div class="flex items-center">
          <div class="avatar">
            <LoadImage class="avatar" :src="item.task.logo" :default="require('@/assets/images/avatar-default.png')"></LoadImage>
          </div>
          <div>
            <div class="flex items-center">
              <span class="name">{{item.task.projectName}}</span>
              <div class="tag" v-if="item.task.taskTypeName">{{item.task.taskTypeName}}</div>
            </div>
            <div class="desc">{{item.task.taskTitle}}</div>
          </div>
        </div>
        <div class="count-list">
          <div>
            <div>{{Number(item.left)+Number(item.complete)}}</div>
            <span>任务数量</span>
          </div>
          <div>
            <div>{{item.left}}</div>
            <span>剩余数量</span>
          </div>
          <div class="theme-text pointer" @click.stop="toUserTask(item.task?.taskId, 0)">
            <div>{{item.going}}</div>
            <span class="theme-text">进行中</span>
          </div>
          <div class="theme-text pointer" @click.stop="toReviewTask(item.task?.taskId)">
            <div>{{item.waitingAudit}}</div>
            <span class="theme-text">待审核</span>
          </div>
          <div class="theme-text pointer" @click.stop="toUserTask(item.task?.taskId, 1)">
            <div>{{item.complete}}</div>
            <span class="theme-text">已完成</span>
          </div>
        </div>
      </div>
      <div class="flex items-center">
        <div class="status-selling" v-if="item.showTaskState">{{item.statusDesc}}</div>
        <div :class="[item.autoTopStatus == 1 ? 'status-stop' : 'status-open']" v-if="item.autoTopStatus != 0 && item.autoTopStatusDesc">
          <img v-if="item.autoTopStatus == 2" src="@/assets/images/top-open.png">
          <img v-if="item.autoTopStatus == 1" src="@/assets/images/top-stop.png">
          <span>{{item.autoTopStatusDesc}}</span>
        </div>
        <div :class="[item.autoRecStatus == 1 ? 'status-stop' : 'status-open']" v-if="item.autoRecStatus != 0 && item.autoRecStatusDesc">
          <img v-if="item.autoRecStatus == 2" src="@/assets/images/recom-open.png">
          <img v-if="item.autoRecStatus == 1" src="@/assets/images/recom-stop.png">
          <span>{{item.autoRecStatusDesc}}</span>
        </div>
        <div :class="[item.autoRefreshStatus == 1 ? 'status-stop' : 'status-open']" v-if="item.autoRefreshStatus != 0 && item.autoRefreshStatusDesc">
          <img v-if="item.autoRefreshStatus == 2" src="@/assets/images/reflesh-open.png">
          <img v-if="item.autoRefreshStatus == 1" src="@/assets/images/reflesh-stop.png">
          <span>{{item.autoRefreshStatusDesc}}</span>
        </div>
        <div class="status-vip" v-if="item.task.vipLevel !== 0">
          <img src="@/assets/images/vip.png">
          <span>会员任务</span>
        </div>
      </div>
      <!-- 操作 -->
      <div class="bottom-row">
        <div class="flex items-center">
          <div class="task-id">
            <span>ID：{{item.task.taskId}}</span>
            <img src="@/assets/images/icon-copy.png" @click.stop="copyTaskId(item.task.taskId)">
          </div>
          <div class="time-row">
            <div class="timeout mr8" v-if="item.task.topCountDown">
              <img src="@/assets/images/icon-clock.png">
              <div class="mr8">置顶时间</div>
              <CountDown :time="item.task.topCountDown*1000"></CountDown>
            </div>
            <div class="timeout" v-if="item.task.recCountDown">
              <img src="@/assets/images/icon-clock.png">
              <div class="mr8">推荐时间</div>
              <CountDown :time="item.task.recCountDown*1000"></CountDown>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <el-popover placement="bottom" width="160" trigger="click" popper-class="custom-popover" v-if="item.supportActions.slice(4).length > 0">
            <div slot="reference" class="btn-gray">更多功能</div>
            <div class="more-btns">
              <template v-for="(btnInfo, i) in item.supportActions.slice(4)">
                <UploadImage v-if="btnInfo.act == 16" :key="i" @success="handleModifyImg($event, item.task.taskId)">
                  <div class="btn-items" :key="i">
                    <img :src="getActionBtnIcon(btnInfo)">
                    <span>{{btnInfo.text}}</span>
                  </div>
                </UploadImage>
                <div v-else class="btn-items" :key="i" @click="handleAction(btnInfo, item.task)">
                  <img :src="getActionBtnIcon(btnInfo)">
                  <span>{{btnInfo.text}}</span>
                </div>
              </template>
              
            </div>
          </el-popover>
          <template v-for="(btnInfo, i) in item.supportActions.slice(0, 4)">
            <UploadImage v-if="btnInfo.act == 16" :key="i" @success="handleModifyImg($event, item.task.taskId)">
              <div class="btn-border" :key="i">{{btnInfo.text}}</div>
            </UploadImage>
            <div v-else :class="[btnInfo.act == 2 || btnInfo.act == 12 ? 'btn-fill btn-fill-theme' : btnInfo.act == 9 ? 'btn-fill btn-fill-green' : 'btn-border']" 
             :key="i" @click.stop="handleAction(btnInfo, item.task)">{{btnInfo.text}}</div>
          </template>
          
        </div>
      </div>
    </div>

    <!-- 推荐/置顶弹窗  -->
    <RecomTopPopup ref="recomTopPopupRef" @confirm="manageTask"/>
    <!-- 加价/加量 -->
    <AdjustPopup ref="adjustPopupRef" @confirm="manageTask"></AdjustPopup>
    <!-- 导出数据 -->
    <ExportDataPopup ref="exportDataPopupRef"></ExportDataPopup>
    <!-- 任务详情弹窗 -->
    <TaskDetailPopup ref="detailPopupRef"></TaskDetailPopup>
  </div>
</template>

<script>
import RecomTopPopup from './components/RecomTopPopup.vue'
import AdjustPopup from './components/AdjustPopup.vue'
import ExportDataPopup from './components/ExportDataPopup.vue'
import TaskDetailPopup from './components/TaskDetailPopup.vue'
import LoadImage from '@/components/LoadImage'
import CountDown from '@/components/CountDown'
import UploadImage from '@/components/UploadImage'
import { copyUtil } from '@/utils/util.js'
import { taskManageApi, changeTaskLogoApi } from '@/api/task.js'
export default {
  name: "TaskList",
  components: {
    RecomTopPopup,
    AdjustPopup,
    ExportDataPopup,
    TaskDetailPopup,
    LoadImage,
    CountDown,
    UploadImage
  },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    taskList() {
      if(this.list.length == 0) return []
      let resList = this.list
      resList.forEach(item => {
        const ends = []
        const list = []
        item.supportActions.forEach(item1 => {
          if(item1.act == 2 || item1.act == 9 || item1.act == 12) {
            ends.push(item1)
          } else {
            list.push(item1)
          }
        })
        if(item.supportActions.length <= 4) {
          item.supportActions = list.concat(ends)
        } else {
          list.splice(4 - ends.length, 0, ...ends)
          item.supportActions = list
        }
        
      })
      return resList
    }
  },
  methods: {
    copyTaskId(val) {
      copyUtil(val).then(() => {
        this.$tips({message: '复制成功', type: 'success'})
      }).catch(err => {
        console.log(err)
      })
    },
    getActionBtnIcon(info) {
      const act = info.act
      if(act == 18) return require('@/assets/images/more-btns/icon-jpms.png')
      if(act == 1) return require('@/assets/images/more-btns/icon-shyh.png')
      if(act == 3) return require('@/assets/images/more-btns/icon-sdsx.png')
      if(act == 4) return require('@/assets/images/more-btns/icon-xj.png')
      if(act == 7) return require('@/assets/images/more-btns/icon-jj.png')
      if(act == 8) return require('@/assets/images/more-btns/icon-xgrw.png')
      if(act == 12) return require('@/assets/images/more-btns/icon-publish.png')
      if(act == 16) return require('@/assets/images/more-btns/icon-xgtb.png')
      if(act == 100) return require('@/assets/images/more-btns/icon-dc.png')
      if(act == 1000) {
        if(info?.navigate?.extInfo?.type == 1) return require('@/assets/images/more-btns/icon-dszd.png')
        if(info?.navigate?.extInfo?.type == 2) return require('@/assets/images/more-btns/icon-dstj.png')
      }
    },
    handleAction(btnInfo, taskDetail) {
      console.log(btnInfo)
      const act = btnInfo.act
      const taskId = taskDetail.taskId
      if(act == 1) {
        // 审核任务
        this.toReviewTask(taskId)
      }
      else if(act == 5 || act == 6) {
        // 置顶/推荐
        this.$refs.recomTopPopupRef.open({act, taskId})
      }
      else if(act == 7) {
        // 加量/加价
        this.$refs.adjustPopupRef.open({act, detail: taskDetail})
      }
      else if(act == 8 || act == 12 || act == 15) {
        // 8已上架修改 12重新上架 15未上架修改
        this.$router.push(`/publishManage/publishTask?taskId=${taskId}&act=${act}`)
      }
      else if(act == 17) {
        // 自动刷新
        const detail = encodeURIComponent(JSON.stringify(taskDetail))
        this.$router.push(`/auto?taskId=${taskId}&type=0&taskDetail=${detail}`)
      }
      else if(act == 18) {
        // 秒杀
        const detail = encodeURIComponent(JSON.stringify(taskDetail))
        this.$router.push(`/seckill?taskId=${taskId}&taskDetail=${detail}`)
      }
      else if(act == 100) {
        // 导出数据
        this.$refs.exportDataPopupRef.open({taskId})
      }
      else if(act == 1000) {
        // 定时置顶/推荐
        const type = btnInfo?.navigate?.extInfo?.type
        const detail = encodeURIComponent(JSON.stringify(taskDetail))
        this.$router.push(`/auto?taskId=${taskId}&type=${type}&taskDetail=${detail}`)
      }
      else {
        // act == 2 || act == 3  || act == 4 || act == 9 || act == 10 || act == 11
        this.$confirm('确定执行此操作吗？').then(action => {
          if(action == 'confirm') {
            taskManageApi({ 'type': act,'taskId': taskId }).then(res => {
              this.$emit('update')
              this.$tips({ type: 'success', message: res.msg })
            }).catch(err => {
              this.$tips({ type: 'error', message: err })
            })
          }
        }).catch(err => {})
      }
    },
    handleModifyImg(e, taskId) {
      // 修改图标
      changeTaskLogoApi({
        'taskId': taskId,
        'logo': e.ossUrl
      }).then(res => {
        this.$emit('update')
        this.$tips({ type: 'success', message: res.msg })
      }).catch(err => {
        this.$tips({ type: 'error', message: err })
      })
    },
    manageTask(data) {
      taskManageApi(data).then(res => {
        console.log(res)
        this.$tips({ type: 'success', message: res.msg })
        this.$emit('update')
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      }).catch(err => {
        this.$tips({ type: 'error', message: err })
      })
    },
    toReviewTask(taskId) {
      this.$router.push(`/taskReview?taskId=${taskId}`)
    },
    openDetail(taskId) {
      this.$refs.detailPopupRef.open(taskId)
    },
    toUserTask(taskId, type) {
      this.$router.push(`/publishManage/userTasks?taskId=${taskId}&type=${type}`)
    }
  }
};
</script>

<style lang="scss" scoped>
.task {
  padding: 24px 24px 12px;
  border-radius: 12px;
  margin-top: 12px;
  background-color: #fff;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin-right: 16px;
      overflow: hidden;
    }
    .name {
      color: #65666F;
      font-size: 16px;
    }
    .desc {
      font-size: 16px;
      font-weight: 500;
      margin-top: 4px;
    }
    .tag {
      height: 20px;
      line-height: 20px;
      border-radius: 10px;
      padding: 0 4px;
      font-size: 12px;
      border: 1px solid #65666F;
      margin-left: 8px;
    }
    .count-list {
      display: flex;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      &>div {
        margin-left: 24px;
      }
      span {
        color: #65666F;
        font-size: 12px;
        font-weight: normal;
        margin-top: 2px;
      }
    }
  }
}

.status-selling {
  position: relative;
  padding: 0 8px 0 16px;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  border-radius: 4px;
  color: #08C8BD;
  background-color: #E6F9F8;
  margin-right: 12px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #08C8BD;
  }
}
.status-stop,
.status-open,
.status-vip {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 26px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 12px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
.status-stop {
  color: var(--theme-color-red);
  background-color: #FFEAE9;
}
.status-open {
  color: var(--theme-color-green);
  background-color: #E9FFEB;
}
.status-vip {
  color: var(--theme-color-yellow);
  background-color: #FFF6E0;
}
.bottom-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  padding-top: 13px;
  border-top: 1px solid #F3F3F3;
  .task-id {
    display: flex;
    align-items: center;
    color: #B0B3BF;
    font-size: 14px;
    margin-right: 24px;
    img {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      cursor: pointer;
    }
  }
  .timeout {
    display: flex;
    align-items: center;
    color: #65666F;
    font-size: 14px;
    color: var(--theme-color);
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .btn-gray,
  .btn-border,
  .btn-fill {
    width: 92px;
    height: 34px;
    line-height: 34px;
    border-radius: 4px;
    margin-left: 8px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .btn-gray {
    border: 1px solid #E8E8E8;
  }
  .btn-border {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
  }
  .time-row {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

// 更多功能
.more-btns {
  ::v-deep label{
    display: block;
  }
  .btn-items {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 46px;
    padding-left: 10px;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
    &:hover {
      background: #FFF2ED;
      color: var(--theme-color);
    }
  }
}
</style>